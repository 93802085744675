<template>
  <v-list-item class="list-item-multiline d-flex">
    <div class="d-flex my-auto flex-wrap pt-2 text-break">
      {{ text }}
    </div>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItemMultiline',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>
